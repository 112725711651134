.home_slide1,.home_slide2,.home_slide3{
    background-image: url(../../images/carousel_slide1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.carousel_secound_slide{
    height:calc(100vh - 70px);
    display: flex;
    justify-content: center;
    align-items: center;
    .carousel_secound_slide_content-supheading{
        font-size:40px;
        font-weight: 500;
        font-family: $font-family;
    }

    .carousel_secound_slide_content{
       text-align: center;
       color: $base;
       h2{
        font-size:70px;
        font-weight: 500;
        font-family: $font-family;
       }
       h5{
        font-size:30px;
        font-weight: 500;
        font-family: $font-family;
       }
       button{
        width: 170px;
        height: 50px;
        font-size: 18px;
        font-weight: 500;
        background-color: $base;
        color: $theme;
        border: none;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin: 40px auto 0;
       }

    }
}

//welcome section

.home_welcom_section{
    display: flex;
    margin: 145px 0px 100px 0;
    .home_welcom_section-img{
        width:50%;
        // height: 340px;
    }
    .home_welcom_section-content{
        width: 50%;
        padding-right: 40px;
        margin: auto 0;
        h2{
            font-size: 36px;
            font-weight: 500;
            color: #000000;
            margin: 0;
            font-family: $font-family;
        }
        p{
            font-size: 18px;
            font-weight: 400;
            color: #000000;
            margin: 30px 0px 40px 0px;
            font-family: $font-family;
        }
        button{
            color: #4987FF;
            border: 1px solid #4987FF;
            border-radius: 5px;
            background-color: #fff;
            font-weight: 500;
            width: 170px;
            height: 45px;
            font-size: 18px;
            &:hover{
                background-color: #4987FF;
                color: #fff;
                transition: all 0.5s;
            }
        }
    }
}

//services

.home_service-header{
    display: flex;
    justify-content: space-between;
    .home_service-header_arrowbtn{
        display: flex;
        gap: 40px;
        button{
            all: unset;
            cursor: pointer;
            width:45px;
            height: 60px;
        }
        button:disabled{
            cursor: default;
            img{
                filter: grayscale(1);
            }
        }
        img{
            width: 100%;
            height: 100%;
        }
    }
    h2{
        font-size:36px;
        font-weight: 500;
        color: #000000;
        font-family: $font-family;
    }
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff; /* Change color */
  background-color: rgba(0, 0, 0, 0.5); /* Add a background for better visibility */
  padding: 10px;
  border-radius: 50%;
}

.swiper-button-next {
  right: 10px; /* Adjust position from the right */
  top: 50%; /* Center vertically */
  transform: translateY(-50%);
}

.swiper-button-prev {
  left: 10px; /* Adjust position from the left */
  top: 50%; /* Center vertically */
  transform: translateY(-50%);
}

.home_service-image_container{
    display: flex;  
    gap: 20px;
}
    .home_service-image{
        position: relative;
        height: 480px;
        background-size: cover;
        margin: 70px 0 0 0;
        img{
            height: 100%;
            object-fit: cover;
            width: calc(100% - 50px);
        }
        .home_service-image-content{
            position: absolute;
            bottom: 30px;
            left: 30px;
            right: 30px;
            min-height: 60px;
        }
        h4,p{
            color: $base;
            margin: 0;
        }
        p{
            font-size: 18px;
            font-weight:400;
        }
        h4{
            font-size: 30px;
            font-weight:500;
        }
    }
    .slick-list{
        margin-right: -50px;
    }

//our work 
.home_our-works{
    margin-top: 100px;
}
.home_our-works-header{
    display: flex;
    justify-content:center;
    gap: 80px;
    .active_works{
        color: $theme;
    }
    h4{
        margin:0;
        font-size: 18px;
        font-weight: 500;
        color:#000000;
        cursor: pointer;
    }
}
.home_our-works-content{
    background-color: $theme;
    padding: 50px;
    font-family: $font-family;
    margin: 50px 0px 100px 0;
    
    .slick-dots li button:before {
        color: white;  /* Set the dots to white */
    }
    
    .slick-dots li.slick-active button:before {
        color: white;  /* Optionally, set the active dot to a different color */
    }
    h2{
        color:$base;
        font-size: 36px;
        font-weight:500;
    }
    .home_our-works-content-card_container{
        display: flex;
        gap: 24px;
        margin: 140px 0 90px 0;
    }
    .home_our-works-content-card{
        position: relative;
        flex-basis: calc(25% - 24px);
        height: 244px;
        border-radius: 10px;
        background-color:$base;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 20px;
        margin: 140px 24px 50px 0;
        .home_our-works-content-card-image{
            position: absolute;
            bottom:80px;
            right: 15px;
            left:15px;
            border-radius: 10px;
            height: 244px;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url(../../images/industrial_Works.jpg);
        }
        .sevices_card1{
            background-image: url(../../images/Works-NLC.jpg);
        }
        .sevices_card2{
            background-image: url(../../images/Works-DAM.jpg);
        }
        .sevices_card3{
            background-image: url(../../images/Works-KodaiyarDam.jpg);
        }
        h5{
            font-size: 18px;
            font-weight: 500;
            color: #000000;
            margin: 0;
        }
    }

    .home_our-works-content-btns{
        display: flex;
        gap: 40px;
        justify-content:end;
        align-items:center;
        margin: 25px 0;
        img{
            width:40px;
            height: 40px;
            cursor: pointer;
        }
    }
}

//our clients 
.home_our_clients-header{
    display:flex;
    justify-content:space-between;
    h2{
        font-size:36px;
        font-weight: 500;
        color: #000000;
        font-family: $font-family;
    }
    p{
        font-size:18px;
        font-weight: 400;
        color: #000000;
        font-family: $font-family;
    }
    .home_our_clients-header_arrowbtn{
        display:flex;
        gap: 40px;
    }
    img{
        width:45px;
        height: 60px;
        cursor: pointer;
        &:hover{
            filter: drop-shadow(2px 4px 6px #00000094)
        }
    }
}
.home_our_clients-content{
    // display: flex;
    // justify-content: space-between;
    margin: 50px 0 100px 0;
    // gap: 20px;
}
.home_our_clients-content-data{
    max-width: 338px;
    width: 100%;
    text-align: center;
    font-family: $font-family;
    padding-right: 24px;
    .home_our_clients-content-data-img{
        img{
            width: 100%;
            height: 110px;
        }
    }
    p{
        font-size: 18px;
        font-weight: 400;
        margin: 30px 0 0 0;
        color: #000000;
    }
}

//gallery
.home_gallery_header{
    display: flex;
    justify-content:space-between;
    align-items: center;
    h2{
        font-size:36px;
        font-weight: 500;
        color: #000000;
        font-family: $font-family;
    }
    button{
        width: 170px;
        height: 45px;
        font-size: 18px;
        font-weight: 500;
        background-color: #fff;
        color: #4987FF;
        border: 1px solid;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
       }
}
.home_gallery_contennt{
    display: flex;
    gap: 20px;
    margin: 50px 0px 100px 0;
    justify-content: space-between;
    flex-wrap: wrap;
    .home_gallery_contennt-img{
        max-width: calc(25% - 20px);
        width: 100%;
        height: 450px;
        img{
            object-fit: cover;
        }
    }
}  