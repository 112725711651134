@media (min-width: 768px) and (max-width: 1199px) {
    .header_navbar .header_navbar-navlinks{
        justify-content: space-between;
    }

}
@media (max-width: 1199px) {
    .contact_background .contact_heading{
        h2{
            font-size:60px;
        }
        h5{
            font-size:25px;
        }
    }
    .carousel_secound_slide .carousel_secound_slide_content {
        h2{
            font-size:60px;
        }
        h5{
            font-size:25px;
        }
    }
    .home_our-works-header{
        gap: 35px;
    }
}
@media (max-width: 991px) {
    .contact_background .contact_heading{
        h2{
            font-size:55px;
        }
        h5{
            font-size:20px;
        }
    }
    .carousel_secound_slide .carousel_secound_slide_content {
        h2{
            font-size:55px;
        }
        h5{
            font-size:20px;
        }
    }
    .carousel_secound_slide .carousel_secound_slide_content-supheading {
        font-size: 35px;
    }

    .about_experts .about_experts-card-container{
        display:block;
    }
    .about_experts .about_experts-card{
        margin: auto;
    .about_experts-card_img-container{
        margin-top: 150px;
        // .about_experts-card_img{
        //     height: 610px;
        // }
    }
    }
    .contact_message{
        width: 100%;
    }
    .contact_message_container{
        flex-direction: column;
        row-gap: 20px;
        h2{
            display: block;
        }
       .contact_message, .contact_map{
        width: 100%;
        .contact_map-data{
            height: 500px;
            iframe{
                border-radius: 20px;
            }
        }
       }
    }
    .contact_message_header{
        display: none;
    }

}
@media (max-width: 767px) {
    .contact_background{
        padding:20px;
    }
    .home_our-works-header{
        flex-direction: column;
        align-items: center;
    }

    .header_navbar-toggler{
        display:block
    }
    .header_navbar-toggler-container{
        display: flex;
        justify-content: space-between;
        width:100%
    }
    .header_navbar{
        display: block;
        height: 100%;
    }
    .header_navbar .header_navbar-navlinks{
        display:none;
        .active{
            color: #4987FF;
            font-weight: 600;
            font-size: 20px;
            border: none;
        }
        .header_navbar-icons{
            justify-content: center;
        }
    }
    .header_navbar .open_nav{
        display:flex;
        flex-direction: column;
        padding: 30px 0;
        gap: 30px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }


    .home_welcom_section{
        display:block;
     .home_welcom_section-content{
        width: 100%;
     }
     .home_welcom_section-img{
        width: 100%;
        margin-top: 40px;
     }
    }

    .feature_card-container .feature_card{
        flex-basis: 100%;
    }
    .about_welcome_section .about_welcome_section_list{
        flex-direction: column;
    }

    .sevices_solutions .sevices_solutions_list{
        flex-direction: column;
    }
    .sevices_solutions_works{
         flex-direction: column;
         img{
            width:auto
         }
        .sevices_solutions_works-img{
            max-width: 100%;
            text-align: center;
        }
    }

    .gallery_container {
        .gallery_container-header{
            flex-direction: column;
            align-items: center;
        }
        .gallery_first_row {
            flex-direction :column;
            align-items: center;
            .gallery_first_row-img1{
                max-width: 350px;
            }
            .gallery_first_row-img2{
                max-width: 350px;
            }
        }
        .gallery_secound_row{
            flex-direction :column;
            align-items: center;
            .gallery_secound_row-img {
                max-width: 350px;
            }
        }
        .gallery_third_row-img{
            max-width: 350px;
            margin: 50px auto;
        }
    }

    .nec_footer_container{
        top: 200px;
        .nec_footer_container-background{
            top: -200px;
        }
    }

    
}
/* Extra small devices (phones) */
@media (max-width: 575px) {
    /* CSS rules for screens smaller than 576px */
    .home_gallery_contennt .home_gallery_contennt-img{
        max-width: 100%;
    }
    .home_service-header,.home_our_clients-header {
        flex-direction: column;
        align-items: center;
        text-align: center;
        row-gap: 35px;
    }
    .carousel_secound_slide .carousel_secound_slide_content{
        h2{
            font-size: 35px;
        }
        h5{
            font-size:16px;
        }
    }
    .contact_background .contact_heading{
        h2{
            font-size:35px;
        }
        h5{
            font-size:16px;
        }
    }
    .carousel_secound_slide .carousel_secound_slide_content-supheading {
        font-size: 25px;
    }
    .home_our-works-content .home_our-works-content-btns{
        padding-top: 35px;
    }
    .carousel_secound_slide .carousel_secound_slide_content button{
        width: 140px;
        height: 40px;
        font-size: 15px;
    }
    .home_gallery_header{
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .sevices_solutions_works img {
        width: 100%;
    }
    .contact_background-container{
        padding:0;
    }
}

@media (max-width:450px) {
    .contact_background{
        padding:0;
    }
}