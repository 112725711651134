.nec_footer_container{
    position: relative;
    top: 350px;
    .nec_footer_container-background{
        position: absolute;
        top: -350px;
        z-index: -1;
    }
}
.nec_footer{
    background-size: cover;
    background-position: top;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin: 0 50px;
    h4{
        color: #000000;
        font-size: 25px;
        font-weight:500;
        margin-bottom: 20px;
    }
    p{
        font-size: 15px;
        font-weight: 500;
        color: #000000;
    }
    .nec_footer_logo{
        max-width: 380px;
        width: 100%;
        .nec_footer_logo_img{
            width: 160px;
            height: 100px;
            img{
                width:100%
            }
        }
        p{
            font-size:15px;
            font-weight: 500;
            color: #000000;
            margin-top: 40px;
        }
    }
    .nec_footer_location{
        max-width: 200px;
        width: 100%;
    }
    .nec_footer_links{
        max-width: 165px;
        width: 100%;
        ul{
            list-style-type: none;
            padding: 0;
            
            li {
                margin-bottom:10px;
                a{
                font-size: 15px;
                font-weight: 500;
                color: #000000;
                cursor: pointer;
                text-decoration: none;
                &:hover{
                    color: #4987FF;
                    font-weight: 600;
                }
            }
            .active{
                color: #4987FF;
                font-weight: 600;
            }
        }
        }
    }
    .nec_footer_products{
        max-width: 200px;
        width: 100%;
    }
    .nec_footer_contact{
        max-width: 200px;
        width: 100%;
        .nec_footer_contact-icon{
            display: flex;
            gap: 5px;
            align-items: center;
        }
        img{
            width: 15px;
            height: 15px;
            margin: 0px 10px 1rem 0;
        }
    }
}
.nec_footer_socialmedia-icons{
    display: flex;
    gap: 30px;
    justify-content: center;
    margin: 35px 0 15px 0;
    .nec_footer_socialmedia-icons-img{
        width: 30px;
        height: 30px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
        }
    }
}
.nec_footer_copyright{
    text-align: center;
    p{
        font-size:15px;
        font-weight: 400;
        color: #000000;
    }
}
.active_footer_link{
    color: #4987FF;
    font-weight: 600;
}