.service_slide1,.service_slide2,.service_slide3{
    background-image: url(../../images/Service_Baner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
//solution
.sevices_solutions{
    margin: 100px 0 50px 0;
     h2{
        font-size: 36px;
        font-weight: 500;
        color: #000000;
        font-family: $font-family;
        margin-bottom: 30px;
    }
    p{
        font-size: 18px;
        font-weight: 400;
        color: #000000;
        font-family: $font-family;
    }
    .sevices_solutions_list {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;

        h5 {
            font-size: 18px;
            font-weight: 500;
            color: #000000;
            margin: 20px 0 0 0;
        }

        img {
            width: 10px;
            height: 10px;
            margin-right: 10px;
        }
    }
}

//works
.sevices_solutions_works{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin: 50px 0px 100px 0;
    .sevices_solutions_works-img{
        max-width: 530px;
        width: 100%;
        height: 500px;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
    }
}
.sevices_solutions-heading{
    font-size: 36px;
}