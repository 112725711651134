.gallery_slide1,.gallery_slide2,.gallery_slide3{
    background-image: url(../../images/Gallery_Baner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.gallery_container{
    margin: 100px 0;
    padding: 0 50px;
    .gallery_container-header{
        display: flex;
        justify-content: center;
        gap: 30px;
        h4{
            font-size: 18px;
            font-weight: 400;
            color: black;
            margin: 0;
        }
    }
    h4,p{
        color: $base;
        margin: 0;
    }
    p{
        font-size: 18px;
        font-weight:400;
    }
    h4{
        font-weight:500;
        font-size: 30px;
    }
    .gallery_first_row{
        display:flex;
        gap: 10px;
        justify-content: space-between;
        margin-top: 50px;
        .gallery_first_row-img1{
            max-width: 30%;
            width: 100%;
            height: 400px;
            border-radius: 20px;
            background-image: url(../../images/Gallery_1.jpg);
            background-repeat: no-repeat;
            background-size:cover;
            display: flex;
            align-items: flex-end;
            padding: 15px;
        }
        .gallery_first_row-img2{
            max-width:65%;
            width: 100%;
            height: 400px;
            border-radius: 20px;
            background-image: url(../../images/Gallery_2.jpg);
            background-repeat: no-repeat;
            background-size:cover;
            display: flex;
            align-items: flex-end;
            padding: 15px;
        }
        
    }

    .gallery_secound_row{
        display:flex;
        gap: 10px;
        justify-content: space-between;
        margin-top: 50px;
        .gallery_secound_row-img{
            max-width: 30%;
            width: 100%;
            height: 400px;
            border-radius: 20px;
            display: flex;
            align-items: flex-end;
            padding: 15px;
        }
        .gallery_secound_row-firstimg{
            background-image: url(../../images/Gallery_3.jpg);
            background-repeat: no-repeat;
            background-size:cover;
        }
        .gallery_secound_row-secoundimg{
            background-image: url(../../images/Gallery_4.jpg);
            background-repeat: no-repeat;
            background-size:cover;
        }
        .gallery_secound_row-thirdimg{
            background-image: url(../../images/Gallery_5.jpg);
            background-repeat: no-repeat;
            background-size:cover;
        }
    }
    .gallery_third_row-img{
        margin: 50px 0;
        width: 100%;
        height: 400px;
        border-radius: 20px;
        background-image: url(../../images/Gallery_6.jpg);
        background-repeat: no-repeat;
        background-size:cover;
        display: flex;
        align-items: flex-end;
        padding: 15px;
    }
    // .galary_contant_data{
    //     min-height: 150px;
    // }

}