.feature_slide1,.feature_slide2,.feature_slide3{
    background-image: url(../../images/Feature-Baner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.feature_card-container{
    display: flex;
    flex-wrap: wrap;
    margin: 100px 0;
    column-gap: 20px;
    row-gap: 30px;
    justify-content: space-between;
    .feature_card{
        flex: 0 0 calc(33.33% - 20px); /* Adjusts the width and maintains gap */
        box-sizing: border-box; /* Ensures padding and border are included in the width/height */
        border: 1px solid #A4A4A4;
        border-radius: 20px;
        padding: 10px; /* Optional: adds padding inside the divs */
        text-align: center;
        h3{
            margin: 0 0 10px 0;
            font-size: 22px;
            color: #000000;
            font-weight: 500;
        }
        p{
            margin: 0;
            font-size: 15px;
        }
        .feature_card-image{
            width: 80px;
            height: 80px;
            margin: 2px auto 20px auto;
        img{
            width: 100%;
            height: 100%;
        }
        }
    }

}