.about_slide1,
.about_slide2,
.about_slide3 {
    background-image: url(../../images/About_Slide1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.about_welcome_section {
    margin: 100px 0 50px 0;

    h2 {
        font-size: 36px;
        font-weight: 500;
        color: #000000;
        font-family: $font-family;
        margin-bottom: 30px;
    }

    p {
        font-size: 18px;
        font-weight: 400;
        color: #000000;
        font-family: $font-family;
    }

    .about_welcome_section_list {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;

        h5 {
            font-size: 18px;
            font-weight: 500;
            color: #000000;
            margin: 25px 0 0 0;
        }

        img {
            width: 10px;
            height: 10px;
            margin-right: 10px;
        }
    }
}

//our experts
.about_experts {
    background-color: $theme;
    padding: 50px;
    margin-bottom: 100px;
    h2{
        font-size: 36px;
        font-weight: 500;
        color: #fff;
    }
    .about_experts-card-container{
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin-top: 140px;
}

    .about_experts-card {
        max-width: 300px;
        background-color: #fff;
        border-radius: 20px;
        width: 100%;
        .about_experts-card_img-container {
            height: 150px;
            position: relative;
            border-bottom: 1px solid #000000;

            .about_experts-card_img {
                height: 250px;
                width: 100%;
                position: absolute;
                bottom: 0;
                display: flex;
                justify-content: center;
                img {
                        height: 100%;
                        max-width: 100%;
                    }
            }
        }
        h4{
            font-size: 20px;
            font-weight: 500;
            color: #000000;
            text-align: center;
            margin: 30px 0 0 0;
        }
        h5{
            text-align: center;
            color: #A4A4A4;
            font-size: 15px;
            margin: 8px 0 0 0;
        }
        .about_experts-socialmedia{
            display: flex;
            justify-content: center;
            gap: 20px;
            align-items: center;
            max-width: 180px;
            width: 100%;
            margin: 25px auto;
            padding: 18px 0;
            border-top: 3px solid #4987FF;
            border-radius: 3px;
            img{
                width: 25px;
                height: 25px;
                cursor: pointer;
            }
            }
    }

}