.contact_slide1,.contact_slide2,.contact_slide3{
    background-image: url(../../images/About_Slide1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.contact_information{
    display: flex;
    justify-content: space-between;
    margin: 50px 0;
    flex-wrap: wrap;
    row-gap: 25px;
    h2{
        margin-top: 0;
    }
    .contact_information-data{
        display: flex;
        gap: 20px;
    }
    img{
        width: 30px;
        height: 30px;
    }
    p{
        margin: 0;
        font-size: 18px;
        font-weight: 500;
    }

}


.contact_background-container{
    padding: 0 50px;
}
.contact_message_container{
    display: flex;
    margin-bottom: 100px;
    h2{
        display: none;
    }

    .contact_map{
        width: 50%;
        .contact_map-data{
            position: relative;
            right: 15px;
            width: calc(100% + 15px);
            box-sizing: border-box;
            height: 100%;
            iframe{
                width: 100%;
                height: 100%;
                box-sizing: border-box;
            }
        }
    }
}
.contact_message_header{
    display: flex;
    flex-basis: 50%;
    h2{
        margin: 0 0px 30px 15px;
        width: 50%;
        font-size: 36px;
    }
}
.contact_container{
    margin-top: 60px;
    h2{
        font-size: 36px;
    }
}

.contact_background{
    width: 100%;
    // position: relative;
    background-image: url("../../images/Contact_Background.svg");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 50px 0px 50px;
    margin: 100px 0;
img{
    width: 100%;
    object-fit: contain;
}
.contact_heading{
    // position: absolute;
    // top: 64px;
    // left: 104px;
    color: #ffffff;
    font-family: "Manrope";
    padding: 14px;
    h2{
        font-size: 70px;
        font-weight: 500;
    }
    h5{
    font-size: 30px;
    font-weight: 500;
    }
}
    
}

.contact_message{
    width: 50%;
    .contact_message-form{
        border-radius: 20px;
        background-color:$theme;
        position: relative;
        z-index: 1;
        padding: 25px;
        box-sizing: border-box;
        label{
            color: #ffffff;
            font-size: 20px;
            margin: 10px 0;
            display: inline-block;
        }
        input{
            width: 100%;
            padding: 15px 10px;
            border-radius: 5px;
            border: none;
            outline: none;
            box-sizing: border-box;
        }
        .error_input{
            border: 1px solid #e90000e6;
        }
        textarea{
            width: 100%;
            padding: 15px 10px;
            border-radius: 5px;
            border: none;
            outline: none;
            box-sizing: border-box;
        }
        button{
            width: 100%;
            padding: 15px 0;
            background-color: black;
            color: #ffffff;
            border: none;
            cursor: pointer;
            margin-top: 50px;
            font-size: 18px;
        }
        p{
            margin: 0;
            font-size: 14px;
            color: #e90000e6;
            font-weight: 600;
    }
    }
}

.contact_background{
    form{
        display: flex;
        flex-direction: column;
        row-gap: 40px;
    }
    .contact_message_conatiner{
        display: flex;
        justify-content: end;
    }
    .contact_message-form{
        background-color: #ffffff;
        box-shadow: 4px 4px 26.7px 0px #00000040;
        h2{
            font-size: 30px;
            font-weight: 500;
            color: #727272;
            margin-bottom: 10px;
        }
        label{
            color: #727272;
        }
        input{
            width: 100%;
            padding: 15px 10px;
            border-radius: 0;
            border: none;
            outline: none;
            box-sizing: border-box;
            border-bottom: 2px solid #E6E6E6;
            font-size: 20px;
            &::placeholder{
                font-size: 20px;
                color: #727272;
            }
        }
        textarea{
            border: 1px solid #727272;
        }
        button{
            margin-top: 0;
            border-radius: 40px;
        }
    
    }
}