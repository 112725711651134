
.header_navbar{
    display:flex;
    height: 75px;
    justify-content: space-between;
    align-items: center;
    background-color:#FFFFFF;
    padding: 12px 30px;
    h2,h4,h5{
        margin:0
    }
    .header_navbar-logo{
        width: 129px;
        height:60px;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .header_navbar-navlinks{
        display: flex;
        gap:30px;
    }
    .header_navbar-navlinks{
        a{
            font-size: 15px;
            font-weight: 500;
            color: #000000;
            font-family: $font-family;
            text-decoration: none;
            text-align: center;
        }
        .active{
            border-bottom: 4px solid #4987FF;
            border-radius: 3px;
            color: #4987FF;
        }
    }
    .header_navbar-icons{
        display: flex;
        gap:30px;
        align-items: center;
        img{
            cursor: pointer;
        }
    }

}
.header_navbar-toggler{
    display:none;
        background: none;
        border: none;
        color: black;
        font-size: 1.5rem;
}
.header_popover{
    p{
        margin: 10px;
    }
}